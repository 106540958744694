import React from "react";
// import classNames from "classnames";
import { Panel } from "@molecules";

const Panels = ({ panels }) => {
  return (
    <div className="grid grid-cols-1">
      {!!panels.length && panels.map(panel => <Panel {...panel} />)}
    </div>
  );
};

export default Panels;
