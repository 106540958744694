import React from "react";
// import classNames from "classnames";
import { Text, Image, Carousel } from "@atoms";
import { Icon, AppLink } from "@base";
import classNames from "classnames";

const Panel = ({
  heading,
  subheading,
  copy,
  logos,
  testimonials,
  icon,
  buttons,
  inverse,
  image,
  bottomImage,
  headshot,
}) => {
  return (
    <section
      className={classNames(
        "py-16 lg:py-20 text-center flex flex-col items-center justify-center relative w-full",
        {
          "bg-white text-black": !inverse,
          "bg-black text-white": inverse,
        }
      )}
    >
      {image && <Image fill name={`${image}.jpg`} className="opacity-90" />}
      {image && (
        <div className="absolute top-0 left-0 right-0 z-10 bg-gradient-to-t from-transparent to-black opacity-50 h-[50%]" />
      )}
      {image && (
        <div className="absolute bottom-0 left-0 right-0 z-10 bg-gradient-to-b from-transparent to-black opacity-50 h-[50%]" />
      )}
      <div className="relative z-20 px-3 sm:px-6 flex flex-col items-center justify-center w-full">
        <Icon
          name={heading
            .toLowerCase()
            .replaceAll(" ", "_")
            .replace(/('|’)/g, "")}
          className="h-16 sm:h-20 md:h-24 w-full pb-6 max-w-full"
          fitHeight
          // encode
          // eslint-disable-next-line react/no-unstable-nested-components
          placeholder={() => (
            <Text variant="h2" className="my-3">
              {heading}
            </Text>
          )}
        />
        <Text
          variant="h3"
          className="my-3 px-3 sm:px-6 md:my-8 italic lg:my-16"
        >
          {subheading}
        </Text>
        {headshot && (
          <div className="max-w-lg xl:max-w-xl mt-6 -mb-6 sm:my-0 px-3 sm:px-6 w-full">
            <div className="w-full mb-12 relative pb-[100%] sm:pb-[67%]">
              <Image fill name={`${headshot}.jpg`} />
            </div>
          </div>
        )}
        <Text
          className={classNames(
            "prose max-w-lg xl:max-w-xl px-3 sm:px-6 text-lg xl:text-xl text-left leading-normal my-3",
            {
              "prose-p:text-white": inverse,
              "prose-p:text-black": !inverse,
            }
          )}
        >
          {copy}
        </Text>
        {!!logos?.length && (
          <ul className="grid grid-cols-2 max-w-3xl px-3 sm:px-6 lg:grid-cols-4 gap-6 my-6 lg:my-10 pt-3 flex-wrap justify-center w-full">
            {!!logos?.length &&
              logos.map(logo => (
                <li className="p-1 sm:p-3 px-3 sm:px-6 -mb-3 italic">
                  <AppLink
                    to={logo.url}
                    className={classNames(
                      "block p-2 h-20 w-full relative transition duration-200",
                      {
                        "hover:opacity-50 cursor-pointer": logo.url,
                        "pointer-events-none": !logo.url,
                      }
                    )}
                  >
                    <Image alt={logo.name} name={logo.image} />
                  </AppLink>
                </li>
              ))}
          </ul>
        )}
        {!!testimonials?.length && (
          <>
            <div className="hidden sm:block relative w-full max-w-2xl px-3 sm:px-6">
              <Carousel>
                {testimonials.map(testimonial => (
                  <div className="flex h-full flex-col w-full items-center justify-center max-w-xl mx-auto px-3">
                    <blockquote className="prose prose-invert text-left leading-normal my-6 w-full prose-p:text-white">
                      <Text className="italic text-xl">
                        {testimonial.quote}
                      </Text>
                      <cite className="block -mt-3 not-italic text-right ml-auto text-base max-w-sm">
                        – {testimonial.citation}
                      </cite>
                    </blockquote>
                  </div>
                ))}
              </Carousel>
            </div>
            <div className="relative w-full sm:hidden">
              {testimonials.map(testimonial => (
                <div className="flex flex-col w-full items-center justify-center max-w-xl mx-auto px-3">
                  <blockquote className="prose prose-invert text-left leading-normal my-6 w-full prose-p:text-white">
                    <Text className="italic text-xl">{testimonial.quote}</Text>
                    <cite className="block -mt-3 not-italic text-right ml-auto text-base max-w-sm">
                      – {testimonial.citation}
                    </cite>
                  </blockquote>
                </div>
              ))}
            </div>
          </>
        )}
        {buttons && (
          <ul className="flex flex-wrap items-center justify-center -mb-12 px-3 sm:px-6">
            {buttons.map((button, i) => (
              <li className="m-3">
                <AppLink
                  className="text-white inline-flex items-end p-3 font-bold italic hover:opacity-50 transition duration-200"
                  to={button.url}
                >
                  <Icon
                    name={button.text
                      .toLowerCase()
                      .replaceAll(" ", "_")
                      .replace(/('|’)/g, "")}
                    className="h-12 sm:h-16 w-full max-w-full"
                    fitHeight
                    // encode
                    // eslint-disable-next-line react/no-unstable-nested-components
                    placeholder={() => <Text>{button.text}</Text>}
                  />
                  <Icon
                    name={`chevron_right_${(i % 2) + 1}`}
                    className="h-10 sm:h-12 ml-6 w-full max-w-full"
                    fitHeight
                  />
                </AppLink>
              </li>
            ))}
          </ul>
        )}
        {icon && (
          <Icon
            name={icon}
            className="max-w-[12rem] lg:max-w-[14rem] h-20 mt-16"
            fitHeight
          />
        )}
      </div>
      {bottomImage && (
        <div
          className="w-full -mx-6 relative -mb-16 lg:-mb-20 -mt-[25%] pb-[80%] sm:pb-[66.625%]"
          style={{ paddingBottom: "" }}
        >
          <div className="bg-gradient-to-b from-white top-0 left-0 right-0 to-transparent h-[60%] absolute z-10" />
          <Image
            name={`${bottomImage}.jpg`}
            className="opacity-100"
            fill
            fadeTop
          />
        </div>
      )}
    </section>
  );
};

Panel.defaultProps = {
  inverse: true,
};

export default Panel;
